import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import Homepage from './pages/Homepage'
import ForgotPassword from './pages/ForgotPassword'
import ForgotUsername from './pages/ForgotUsername'
import CreateUser from './pages/CreateUser'
import ConfirmLink from './pages/ConfirmLink'
import ClaimOldProfile from './pages/ClaimOldProfile'
import Login from './pages/Login'
import ManageCredentials from './pages/ManageCredentials'
import { routes } from './routes'
import ForceLogout from './helpers/ForceLogout'

export default () => (
  <Switch>
    <Route exact path={routes.home()} component={Homepage} />
    <Route path={routes.login()} component={Login} />
    <Route path={routes.forgotPassword()} component={ForgotPassword} />
    <Route path={routes.forgotUsername()} component={ForgotUsername} />
    <Route path={routes.createUser()} component={CreateUser} />
    <Route path={routes.confirmLink()} component={ConfirmLink} />
    <Route path={routes.claimOldProfile()} component={ClaimOldProfile} />
    <ProtectedRoute
      path={routes.manageCredentials()}
      component={ManageCredentials}
    />
    <Route component={ForceLogout} />
  </Switch>
)
