import * as React from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { inject, observer } from 'mobx-react'
import { Text } from '../content/text'
import Service from './Service'
import { MutateProps } from '../types/MutateProps'
import aLehdet from '../img/a-lehdet_logo_512x512.png'
import apu from '../img/apu_logo.svg'
import lily from '../img/lily_logo.svg'
import eeva from '../img/eeva_logo.svg'
import * as css from './Services.css'

const deleteLilyProfile = gql`
  mutation deleteCurrentLilyProfile {
    deleteCurrentLilyProfile {
      success
      errorCode
      errorMessage
    }
  }
`

const deleteEevaProfile = gql`
  mutation deleteCurrentEevaProfile {
    deleteCurrentEevaProfile {
      success
      errorCode
      errorMessage
    }
  }
`

type Props = {
  viewerProfiles: any
  client: any
  onProfileDeleteSuccess?: Function
  state: any
} & MutateProps

@observer
class Services extends React.Component<Props, any> {
  render() {
    const { state, viewerProfiles, onProfileDeleteSuccess } = this.props
    const { userAuth } = state

    let servicePathAndQuery = ''
    if (userAuth && userAuth.refreshToken) {
      servicePathAndQuery = `/sso?refreshToken=${userAuth.refreshToken}`
    }

    const profileNames = Object.keys(viewerProfiles).map(
      (key) => viewerProfiles[key].profileType
    )

    return (
      <div className={css.services}>
        <h2 className={css.title}>{Text('USED_SERVICES')}</h2>
        <p className={css.ingress}>
          <Text text={'USED_SERVICES_INGRESS'} />
        </p>
        <div className={css.servicesList}>
          {Object.keys(profileNames).map((key) => {
            if (profileNames[key] === 'Lily') {
              return (
                <Service
                  name="Lily.fi"
                  logo={lily}
                  url={`https://www.lily.fi${servicePathAndQuery}`}
                  key="lily_service"
                  deleteProfileMutation={deleteLilyProfile}
                  deleteText={'DELETE_LILY_PROFILE'}
                  warningText={'DELETE_LILY_PROFILE_ADDITIONAL_TEXT'}
                  onProfileDeleteSuccess={onProfileDeleteSuccess}
                />
              )
            }

            if (profileNames[key] === 'Eeva') {
              return (
                <Service
                  name="Eeva.fi"
                  logo={eeva}
                  url={`https://www.eeva.fi${servicePathAndQuery}`}
                  key="eeva_service"
                  deleteProfileMutation={deleteEevaProfile}
                  deleteText={'DELETE_EEVA_PROFILE'}
                  onProfileDeleteSuccess={onProfileDeleteSuccess}
                />
              )
            }

            return null
          })}
          <Service
            name="Apu.fi"
            logo={apu}
            additionalText={Text('APU_ADDITIONAL_TEXT')}
            url={`https://www.apu.fi${servicePathAndQuery}`}
            key="apu_service"
          />
          <Service
            name="A-lehdet asiakaspalvelu"
            logo={aLehdet}
            additionalText={Text('CUSTOMER_SERVICE_ADDITIONAL_TEXT')}
            subtitle="Yksityisille ja yrityksille"
            url={`https://asiakaspalvelu.a-lehdet.fi${servicePathAndQuery}`}
            key="a-lehdet_service"
          />
        </div>
      </div>
    )
  }
}

export default inject('state')(withApollo<any, any>(Services))
