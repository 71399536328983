import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, execute, makePromise } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import * as get from 'lodash/get'
import { setAuthTokens } from '../helpers/authentication'
import { AuthenticationState } from '../types/AuthenticationState'
import gql from 'graphql-tag'

const { REACT_APP_TOKEN_STORAGE_KEY, REACT_APP_IDENTITY_API_URL } = process.env

const refreshAccessToken = gql`
  mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`

let refreshTokenRequest: Promise<any> | boolean = false

const authLink = setContext(async (_, { headers = {} }) => {
  const userAuth = localStorage.getItem(REACT_APP_TOKEN_STORAGE_KEY)

  const { accessToken, expiryTime, refreshToken }: AuthenticationState = userAuth
    ? JSON.parse(userAuth)
    : { accessToken: undefined, refreshToken: '', expiryTime: 0 }

  const isExpired = Math.round(Date.now() / 1000) > expiryTime
  const context = {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`
    }
  }

  if (accessToken && !isExpired) {
    return context
  } else if (isExpired && refreshToken) {
    const refreshOperation = {
      query: refreshAccessToken,
      variables: { refreshToken },
      context
    }

    const fetchTokens = async () => {
      const link = new HttpLink({ uri: REACT_APP_IDENTITY_API_URL })
      return makePromise(execute(link, refreshOperation))
    }

    refreshTokenRequest =
      refreshTokenRequest instanceof Promise ? refreshTokenRequest : fetchTokens()

    const newTokens = await refreshTokenRequest
    refreshTokenRequest = false

    const { accessToken: newAccessToken } = setAuthTokens(
      get(newTokens, 'data.refreshAccessToken')
    )

    if (newAccessToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${newAccessToken}`
        }
      }
    }
  }

  return headers
})

export default () => {
  const httpLink = new HttpLink({
    uri: REACT_APP_IDENTITY_API_URL
  })

  const cache = new InMemoryCache()

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache
  })

  return client
}
