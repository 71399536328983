// All the routes in the app in a central place. Some routes can take parameters which
// is why functions are used. All values are functions in order to remove guesswork
// and unnecessary conditionals when using the routes. Route parame

export const routes = {
  home: () => '/',
  forgotPassword: () => '/unohditko-salasanasi',
  forgotUsername: () => '/unohditko-kayttajatunnuksesi',
  login: () => '/kirjaudu',
  manageCredentials: () => `/kayttajatunnus`,
  changePassword: () => '/muuta-salasanaa',
  createUser: () => '/luo-tunnus',
  claimOldProfile: () => '/ota-tunnus-kayttoon',
  confirmLink: () => '/vahvista'
}
