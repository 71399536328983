import * as React from 'react'
import qs from 'qs'
import Title from '../components/Title'
import { Text } from '../content/text'
import Logo from '../components/Logo'
import ForgotPasswordConfirmationForm from '../forms/ForgotPasswordConfirmationForm'
import ForgotPasswordForm from '../forms/ForgotPasswordForm'
import Layout from '../layout/Layout'
import Footer from '../components/Footer'

type State = {
  confirmationEmail: string
  username: string
}

class ForgotPassword extends React.Component<any, State> {
  state = {
    username: '',
    confirmationEmail: ''
  }

  onRequestDone = (result) => {
    this.setState({
      confirmationEmail: result.forgotPassword.destination
    })
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  render() {
    const { confirmationEmail, username } = this.state

    const webView =
      qs.parse(location.search, { ignoreQueryPrefix: true }).webview === 'true'

    return (
      <Layout main>
        {!webView && <Logo />}
        <Layout constrained centered>
          {confirmationEmail ? (
            <>
              <Title title={Text('CONFIRM_PASSWORD')}>
                Olemme lähettäneet koodin salasanan uusimista varten:{' '}
                <strong>{confirmationEmail}</strong>
              </Title>
              <ForgotPasswordConfirmationForm
                confirmationDestination={confirmationEmail}
                username={username}
              />
            </>
          ) : (
            <>
              <Title
                title={Text('FORGOT_PASSWORD')}
                text={Text('FORGOT_PASSWORD_INGRESS')}
              />
              <ForgotPasswordForm
                onChange={this.setFormState}
                username={username}
                onCompleted={this.onRequestDone}
              />
            </>
          )}
        </Layout>
        {!webView && <Footer />}
      </Layout>
    )
  }
}

export default ForgotPassword
