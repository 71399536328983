import * as React from 'react'
import classnames from 'classnames'
import { Text } from '../content/text'
import Service from './Service'
import aLehdet from '../img/a-lehdet_logo_512x512.png'
import apu from '../img/apu_logo.svg'
import lily from '../img/lily_logo.svg'
import eeva from '../img/eeva_logo.svg'
import * as css from './ServiceLogos.css'
import * as servicesCss from './Services.css'

export default () => (
  <div className={css.services}>
    <p>
      <strong>
        <Text text={'AUTH_SERVICES'} />
      </strong>
    </p>
    <div className={classnames(servicesCss.servicesList)}>
      <Service
        name="apu.fi"
        logo={apu}
        url="https://www.apu.fi/"
        key="apu_service"
      />
      <Service
        name="lily.fi"
        logo={lily}
        url="https://www.lily.fi"
        key="lily_service"
      />
      <Service
        name="eeva.fi"
        logo={eeva}
        url="https://www.eeva.fi"
        key="eeva_service"
      />
      <Service
        name="A-lehdet asiakaspalvelu"
        subtitle="Yksityisille ja yrityksille"
        logo={aLehdet}
        url="https://asiakaspalvelu.a-lehdet.fi"
        key="a-lehdet_service"
      />
    </div>
  </div>
)
